// THIS IS A GENERATED FILE
import * as Types from "../../../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type UserInstallsQueryVariables = Types.Exact<{ [key: string]: never }>

export type UserInstallsQuery = { __typename?: "Query" } & {
  installationsForUser?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "SourceInstallation" } & Pick<
          Types.SourceInstallation,
          "id" | "name" | "targetType" | "repositorySelection" | "settingsUrl"
        >
      >
    >
  >
}

export type GithubRepoInformationQueryVariables = Types.Exact<{
  githubUrl: Types.Scalars["String"]
}>

export type GithubRepoInformationQuery = { __typename?: "Query" } & {
  githubRepoInformation: { __typename?: "GithubRepoInfoResult" } & Pick<
    Types.GithubRepoInfoResult,
    "exists"
  > & {
      repoInfo?: Types.Maybe<
        { __typename?: "GithubRepoInfo" } & Pick<
          Types.GithubRepoInfo,
          | "htmlUrl"
          | "id"
          | "owner"
          | "name"
          | "fullName"
          | "private"
          | "description"
          | "defaultBranch"
        >
      >
    }
}

export const UserInstallsDocument = gql`
  query userInstalls {
    installationsForUser {
      id
      name
      targetType
      repositorySelection
      settingsUrl
    }
  }
`

/**
 * __useUserInstallsQuery__
 *
 * To run a query within a React component, call `useUserInstallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInstallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInstallsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInstallsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserInstallsQuery,
    UserInstallsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserInstallsQuery,
    UserInstallsQueryVariables
  >(UserInstallsDocument, baseOptions)
}
export function useUserInstallsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserInstallsQuery,
    UserInstallsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserInstallsQuery,
    UserInstallsQueryVariables
  >(UserInstallsDocument, baseOptions)
}
export type UserInstallsQueryHookResult = ReturnType<
  typeof useUserInstallsQuery
>
export type UserInstallsLazyQueryHookResult = ReturnType<
  typeof useUserInstallsLazyQuery
>
export type UserInstallsQueryResult = ApolloReactCommon.QueryResult<
  UserInstallsQuery,
  UserInstallsQueryVariables
>
export const GithubRepoInformationDocument = gql`
  query githubRepoInformation($githubUrl: String!) {
    githubRepoInformation(githubUrl: $githubUrl) {
      exists
      repoInfo {
        htmlUrl
        id
        owner
        name
        fullName
        private
        description
        defaultBranch
      }
    }
  }
`

/**
 * __useGithubRepoInformationQuery__
 *
 * To run a query within a React component, call `useGithubRepoInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGithubRepoInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGithubRepoInformationQuery({
 *   variables: {
 *      githubUrl: // value for 'githubUrl'
 *   },
 * });
 */
export function useGithubRepoInformationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GithubRepoInformationQuery,
    GithubRepoInformationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GithubRepoInformationQuery,
    GithubRepoInformationQueryVariables
  >(GithubRepoInformationDocument, baseOptions)
}
export function useGithubRepoInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GithubRepoInformationQuery,
    GithubRepoInformationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GithubRepoInformationQuery,
    GithubRepoInformationQueryVariables
  >(GithubRepoInformationDocument, baseOptions)
}
export type GithubRepoInformationQueryHookResult = ReturnType<
  typeof useGithubRepoInformationQuery
>
export type GithubRepoInformationLazyQueryHookResult = ReturnType<
  typeof useGithubRepoInformationLazyQuery
>
export type GithubRepoInformationQueryResult = ApolloReactCommon.QueryResult<
  GithubRepoInformationQuery,
  GithubRepoInformationQueryVariables
>
