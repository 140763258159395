import * as React from "react"
import { get } from "lodash"
import { WindowLocation } from "@reach/router"
import { deployNow as text } from "@modules/locales/default.js"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { PickSourceTarget } from "./PickSourceTarget"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import { useGithubRepoInformationQuery } from "@modules/site/create/DeployNow/queries.generated"
import Loading from "@modules/ui/components/Loading"
import { CmsVendor } from "@modules/graphql/types"

export type RepoStepProps = {
  location?: WindowLocation
  frameableCmsVendor?: CmsVendor
  frameable?: boolean
}

function getRepoUrl(location?: WindowLocation) {
  const searchParams = new URLSearchParams(location?.search || "")
  return searchParams.get(`url`)
}

export function RepositoryStep({
  location,
  frameableCmsVendor,
  frameable,
}: RepoStepProps) {
  let errorAlert
  const { data, loading } = useGithubRepoInformationQuery({
    variables: {
      githubUrl: getRepoUrl(location) || "",
    },
  })

  const exists = data?.githubRepoInformation?.exists || false
  if (!exists && !loading) {
    errorAlert = <ErrorAlert>Github repository does not exist.</ErrorAlert>
  }

  const repoInfo = data?.githubRepoInformation?.repoInfo
  const repoName = get(repoInfo, `name`)
  const repoUrl = get(repoInfo, `htmlUrl`)

  return (
    <NarrowSingleColumn>
      <WizardStepHeader title={text.labels.configureRepository} />
      {loading && (
        <Loading
          delay={2000}
          message={"Loading Github information..."}
          bufferSize="padded"
        />
      )}
      {repoName && repoUrl && (
        <PickSourceTarget
          frameableCmsVendor={frameableCmsVendor}
          frameable={frameable}
          repoName={repoName}
          repoUrl={repoUrl}
        />
      )}
      {errorAlert}
    </NarrowSingleColumn>
  )
}
